const TOKEN_KEY = '@TCAuthOperationsGestor:access_token';

const TokenStorage = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
};

export default TokenStorage;
