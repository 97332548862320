const USER_KEY = '@TCAuthOperationsGestor:user';

const UserStorage = {
  getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  },

  saveUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem(USER_KEY);
  },
};

export default UserStorage;
