import axios from 'axios';
import { TokenService } from '../Storage';
import { ApiErrorTrackingService } from '../ErrorTracking';

const ApiService = {
  interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },

  mountReponseInterceptor() {
    this.interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => ApiErrorTrackingService.handleResponseError(error),
    );
  },
};

export default ApiService;
