import {
  AuthenticationError,
  ConnectionError,
  ConflictError,
  BadRequestError,
} from './Errors';

export default {
  handleResponseError(error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          throw new AuthenticationError(
            error.response.status,
            error.response.data.message,
          );
        case 400:
          throw new BadRequestError(
            error.response.status,
            error.response.data.message,
          );
        case 409:
          throw new ConflictError(
            error.response.status,
            error.response.data.errors[0],
          );
        default:
          throw new Error(
            `Problemas na a requisição com servidor de dados. Código: ${error.response.status}`,
          );
      }
    }
    throw new ConnectionError();
  },
};
