import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import Routes from '../../routes';
import theme from '../../theme';
import SnackbarProvider from './Snackbar';
import GlobalStyles from '../../styles';
import ApiService from '../../services/API';
import { SessionProvider } from '../../contexts/Session';

const apiHost = process.env.REACT_APP_AUTH_URL ?? '';

ApiService.init(apiHost);
ApiService.mountReponseInterceptor();

function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Suspense fallback={<></>}>
          <SnackbarProvider>
            <SessionProvider>
              <Routes />
            </SessionProvider>
          </SnackbarProvider>
        </Suspense>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
